import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql } from "gatsby";
import prettyBytes from "pretty-bytes";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Label from "../components/label";
import RelatedArticles from "../components/relatedArticles";
import { Contacts, Download } from "../components/sidebar";
import { formatDate } from "../helper/date";
import { getFilteredSearchLink } from "../helper/link";
import { dtrOptions } from "../helper/richText";
import { getValue } from "../helper/translation";
import Layout from "../modules/layout";
import SEO from "../modules/seo";

const PublicationDetail = ({ data: { publicationGeneral }, pageContext }) => {
  const { settings } = pageContext;
  const { microcopy } = settings;
  const publication = publicationGeneral.publicationDetail;
  const siteSettings = settings;

  if (publication) {
    publication.organizations = publication.organizations || [];
  }

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${publication && publication.title}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Container fluid className="publicationDetail">
        <Row>
          <Col lg={4} className="order-2">
            {publication.author && publication.author.length && (
              <Contacts
                authors={publication.author.map(author => ({
                  name: author.name,
                  image: author.photo && author.photo.fixed,
                  email: author.email,
                  bio: author.biography && author.biography.biography,
                }))}
                locale={publication.node_locale}
              />
            )}
            {publication.document && (
              <Download
                filename={publication.title}
                link={{
                  url: publication.document.file.url,
                  linkText: getValue("actions.download", microcopy),
                }}
                size={prettyBytes(publication.document.file.details.size)}
                locale={publication.node_locale}
                extension={publication.document.file.contentType.split("/")[1]}
              />
            )}
          </Col>
          <Col lg={8} className="order-1">
            <h2 className="title">{publication && publication.title}</h2>
            <div className="publication-date">
              {publication &&
                formatDate(
                  publication.publicationDateTime,
                  publication.node_locale
                )}
            </div>
            <div className="labels">
              {publication &&
                [
                  ...publication.topic,
                  publication.focusOfWork,
                  ...publication.organizations,
                ]
                  .filter(item => !!item)
                  .map(item => (
                    <Label
                      text={item.name}
                      type="secondary"
                      className="label"
                      link={getFilteredSearchLink(
                        item.contentful_id,
                        publication.node_locale
                      )}
                    />
                  ))}
            </div>
            <div className="publication-reader">
              {publication &&
                documentToReactComponents(publication.reader.json, dtrOptions)}
            </div>

            {publication &&
              documentToReactComponents(publication.content.json, dtrOptions)}
          </Col>
        </Row>
      </Container>
      {publicationGeneral.publicationDetail && (
        <RelatedArticles article={publicationGeneral} />
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query publicationDetail($contentful_id: String!, $node_locale: String!) {
    publicationGeneral: contentfulPublicationGeneral(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      id
      contentful_id
      __typename
      publicationDetail {
        node_locale
        title
        document {
          title
          file {
            contentType
            details {
              size
            }
            url
          }
        }
        reader {
          json
        }
        content {
          json
        }
        author {
          name
          email
          biography {
            biography
          }
          photo {
            fixed(width: 80, height: 80, resizingBehavior: FILL, quality: 100) {
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        focusOfWork {
          contentful_id
          name
        }
        organizations {
          contentful_id
          name
        }
        publicationDateTime
        topic {
          contentful_id
          name
        }
        region {
          contentful_id
          id
          name
        }
      }
    }
  }
`;

export default PublicationDetail;
